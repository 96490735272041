import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import * as Api from "../../api";

import Loader from "../Loader";
import UploadFile from "../UploadFile";
import AlertMsg from "../AlertMsg";
import AddModifiers from "./AddModifiers";
import AddOptions from "./AddOptions";
import SwitchInput from "../SwitchInput";

import "../../assets/css/custom.css";

import styles1 from "./AddSettingItem.module.css";

import {
  BTN_COLOR,
  BTN_TEXT_COLOR,
  CURRENCY_LIST,
  CURRENCY_SYMBOL,
  MENU_TYPE,
  THEME_COLOR,
  THEME_COLOR3,
  THEME_COLOR4,
} from "../../constants";
import ArModelView from "../../containers/Restaurant/ArModelView";

const theme = createTheme({
  palette: {
    gray: {
      main: THEME_COLOR4,
    },
  },
});

const textInputCss = {
  "& .MuiInput-underline:before": {
    borderBottom: `1.5px solid ${THEME_COLOR4}`,
  },
  "& .MuiInput-underline:after": {
    borderBottom: `1.5px solid ${THEME_COLOR4}`,
  },
  "& .MuiInput-underline:hover:before": {
    borderBottom: 0,
  },
};

const symbol = CURRENCY_LIST;

const OrderTypes = [
  { name: "Both", value: "both" },
  { name: "Dine In", value: "dinein" },
  { name: "Take Away", value: "takeaway" },
];

const AddSettingItem = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [menuTitle, setMenuTitle] = useState("");
  const [printDisplayMenu, setPrintDisplayMenu] = useState("");
  const [menuCategory, setMenuCategory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [price, setPrice] = useState("");
  const [priceUnit, setPriceUnit] = useState("");
  const [takeawayPriceValue, setTakeawayPriceValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [imageList, setImageList] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [hideMenuThumbNailImg, setHideMenuThumbNailImg] = useState(false);
  const [allergiesValue, setAllergiesValue] = useState("");
  const [nutritionValue, setNutritionValue] = useState("");
  const [dishNotes, setDishNotes] = useState("");
  const [caloriesValue, setCaloriesValue] = useState("");
  const [menuTypeValue, setMenuTypeValue] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [modifierList, setModifierList] = useState([]);
  const [modifierDataList, setModifierDataList] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [optionDataList, setOptionDataList] = useState([]);
  const [disableCurrencyDropdown, setDisableCurrencyDropdown] = useState(true);
  const [menuOrderType, setMenuOrderType] = useState("");
  const [menuEnabled, setMenuEnabled] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    const { currencyIcon, itemData } = props;
    const currencyIconExist =
      typeof currencyIcon !== "undefined" &&
      currencyIcon !== null &&
      currencyIcon !== "";

    if (itemData) {
      const {
        name,
        printName,
        description,
        price: { value, currency },
        images,
        allergies,
        nutritions,
        calories,
        dishnote,
        options,
        extras: { menuType, hideMenuThumbNailImages, menuItemOrderType },
        takeawayPrice,
        enabled,
      } = itemData || {};
      setDisableCurrencyDropdown(
        currencyIconExist && currency === currencyIcon
      );
      setMenuTitle(name);
      setPrintDisplayMenu(printName ?? "");
      setDescriptionValue(description);
      setPrice(value);
      setPriceUnit(currency);
      setTakeawayPriceValue(takeawayPrice?.value);
      setImageList(images);
      setAllergiesValue(allergies);
      setNutritionValue(nutritions);
      setCaloriesValue(calories);
      setDishNotes(dishnote);
      setOptionList(options);
      setOptionDataList(options);
      setMenuTypeValue(menuType ?? "");
      setHideMenuThumbNailImg(hideMenuThumbNailImages ?? false);
      setMenuOrderType(menuItemOrderType ?? OrderTypes?.[0]?.["value"]);
      setMenuEnabled(enabled);
    } else {
      setDisableCurrencyDropdown(currencyIconExist);
      setPriceUnit(currencyIconExist ? currencyIcon : "");
      setMenuTitle("");
      setPrintDisplayMenu("");
      setDescriptionValue("");
      setMenuCategory([]);
      setPrice("");
      setTakeawayPriceValue("");
      setImageList([]);
      setAllergiesValue("");
      setNutritionValue("");
      setCaloriesValue("");
      setDishNotes("");
      setModifierList([]);
      setModifierDataList([]);
      setOptionList([]);
      setOptionDataList([]);
      setHideMenuThumbNailImg(false);
      setMenuTypeValue(MENU_TYPE[0]);
      setMenuOrderType(OrderTypes?.[0]?.["value"]);
      setMenuEnabled(true);
    }
  }, [props.itemData, props.currencyIcon]);

  useEffect(() => {
    if (props.itemData) {
      let modifiers = [];
      props.itemData.modifiers.map((x) => {
        let list = [];
        x.items.map((y) => {
          let index = menuList.findIndex((z) => z._id == y);
          if (index >= 0) {
            list.push(menuList[index]);
          }
        });
        modifiers.push({ ...x, items: list });
      });

      setModifierList(modifiers);
      setModifierDataList(modifiers);
    }
  }, [menuList, props.itemData]);

  useEffect(() => {
    if (props.itemData != null) {
      setMenuCategory(
        categoryList.filter((x) => x._id == props.itemData._idCategory)
      );
    }
  }, [props.itemData, categoryList]);

  useEffect(() => {
    let upload_list = imageList.filter((x) => x.upload == false);
    if (upload_list.length > 0) {
      setImageLoading(true);
    } else {
      setImageLoading(false);
    }
  }, [imageList]);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    let filter =
      "pageSize=100000&pageNum=1&filter__idRestaurant=" + props.restaurantId;
    Api.getMenuCategoryList(filter).then((response) => {
      if (response.success) {
        const data = response.data.rows.sort((a, b) => a?.order - b?.order);
        setCategoryList(data);
        getMenuList();
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        setIsLoading(false);
      }
    });
  };

  const getMenuList = () => {
    setIsLoading(true);
    let filter =
      "?pageSize=300000&pageNum=1&filter__idRestaurant=" +
      props.restaurantId +
      "&orderBy=order&orderByDir=asc";
    Api.getMenuList(filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setMenuList(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (/[^0-9.]/g.test(price)) {
      setMsgAlert({ open: true, message: "Invalid Price", msgType: "error" });
      return;
    }

    if (menuTypeValue === "") {
      setMsgAlert({
        open: true,
        message: "Menu Type is required",
        msgType: "error",
      });
      return;
    }

    if (menuCategory.length === 0) {
      setMsgAlert({
        open: true,
        message: "Category is required",
        msgType: "error",
      });
      return;
    }

    if (/[^0-9.]/g.test(caloriesValue) && caloriesValue) {
      setMsgAlert({
        open: true,
        message: "Calories - Only Numbers are allowed",
        msgType: "error",
      });
      return;
    }

    let data = {
      menuTitle: menuTitle,
      printName: printDisplayMenu,
      menuCategory: menuCategory,
      price: parseFloat(price).toFixed(2),
      takeawayPrice: takeawayPriceValue
        ? parseFloat(takeawayPriceValue).toFixed(2)
        : 0,
      description: descriptionValue,
      priceUnit: priceUnit,
      menuImageList: imageList,
      menuType: menuTypeValue,
      allergies: allergiesValue,
      nutritions: nutritionValue,
      calories: caloriesValue,
      dishnote: dishNotes,
      hideMenuThumbNailImages: hideMenuThumbNailImg,
      menuItemOrderType: menuOrderType,
      enabled: menuEnabled,
    };

    let type = "add";
    if (props.itemData != null) {
      type = "edit";
      data = {
        ...props.itemData,
        ...data,
      };
    }

    onSubmitItem(data, type);
  };

  const onSubmitItem = (data, type) => {
    let imageData = [];
    data.menuImageList.forEach((item, index) => {
      imageData.push(item.name);
    });

    let modifiers = [];
    modifierDataList
      .filter((x) => x._id != "add")
      .map((x) => {
        let mod_data = {
          // price: {
          //   value: x.items.reduce((a, b) => a + b.price.value, 0),
          //   currency: priceUnit,
          // },
          header: x.header,
          required: x.required,
          addUpPrices: x?.addUpPrices ?? false,
          multiSelection: x?.multiSelection ?? false,
          extraAllowed: x?.extraAllowed ?? false,
          defaultSelection: x?.defaultSelection ? x?.defaultSelection : null,
          // modifierOrder: x?.modifierOrder,
          // modifierItemOrder: x?.modifierItemOrder,
          items: x.items.map((y) => y._id),
        };
        if (x?.isNew == true) {
        } else {
          mod_data = { _id: x._id, ...mod_data };
        }

        modifiers.push(mod_data);
      });

    let options = [];
    optionDataList
      .filter((x) => x._id != "add")
      .map((x) => {
        let option_data = {
          header: x.header,
          required: x.required,
          items: x.items,
        };
        if (x?.isNew == true) {
        } else {
          option_data = { _id: x._id, ...option_data };
        }

        options.push(option_data);
      });

    let data1 = {
      name: data.menuTitle,
      printName: data?.printName ? data?.printName : null,
      _idCategory: data.menuCategory[0]._id,
      _idRestaurant: props.restaurantId,
      description: data.description,
      extras: {
        menuType: data.menuType,
        hideMenuThumbNailImages: data.hideMenuThumbNailImages,
        menuItemOrderType: data.menuItemOrderType,
      },
      allergies: data.allergies ? data.allergies : null,
      nutritions: data.nutritions ? data.nutritions : null,
      calories: data.calories ? data.calories : null,
      dishnote: data.dishnote ? data.dishnote : null,
      price: {
        value: data.price,
        currency: data.priceUnit,
      },
      takeawayPrice: {
        value: data.takeawayPrice,
        currency: data.priceUnit,
      },
      images: imageData,
      modifiers: modifiers,
      options: options,
      enabled: data.enabled,
    };
    let datas = JSON.stringify(data1);

    setBtnDisabled(true);
    if (type == "add") {
      Api.addMenu(datas).then((response) => {
        if (response.success) {
          setMsgAlert({
            open: true,
            message: "Added Successfully",
            msgType: "success",
          });
          props.onSubmit();
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setBtnDisabled(false);
      });
    } else {
      Api.updateMenu(datas, data._id).then((response) => {
        if (response.success) {
          setMsgAlert({
            open: true,
            message: "Updated Successfully",
            msgType: "success",
          });
          props.onSubmit();
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setBtnDisabled(false);
      });
    }
  };

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const onRemoveImage = (data) => {
    setImageList([]);
    // let menuImage = [...imageList];
    // let imageIndex = menuImage.findIndex((item) => item.id == data.id);
    // if (imageIndex >= 0) {
    //   menuImage.splice(imageIndex, 1);
    //   setImageList(menuImage);
    // }
  };

  const uploadFile = (data, file) => {
    setImageList((prev) => [...prev, data]);
    setImageLoading(true);

    let formData = new FormData();
    formData.append("image", file);
    Api.uploadImageFile(formData).then((response) => {
      if (response.success) {
        data["name"] = response.data.fileId;
        data["image"] = response.data.location;
        data["upload"] = true;
        setImageList((prev) =>
          prev.map((el) => (el.id == data.id ? { ...el, ...data } : el))
        );
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const uploadError = (err) => {
    setMsgAlert({ open: true, message: err, msgType: "error" });
  };

  const handleOnChangeValue = (type, value) => {
    if (type === "takeaway") {
      setTakeawayPriceValue(value.replace(/[^0-9.]/g, ""));
    } else if (type === "nutritions") {
      setNutritionValue(value);
    } else if (type === "allergies") {
      setAllergiesValue(value);
    } else if (type === "calories") {
      setCaloriesValue(value);
    } else if (type === "description") {
      setDescriptionValue(value);
    } else if (type === "menuType") {
      setMenuTypeValue(value);
    } else if (type === "menuItemOrderType") {
      setMenuOrderType(value);
    }
  };

  const handlerHideMenuThumbNailImg = () => {
    setHideMenuThumbNailImg(!hideMenuThumbNailImg);
  };

  const handlerMenuEnabled = () => {
    setMenuEnabled(!menuEnabled);
  };

  if (isLoading) {
    return <Loader height={{ height: window.innerHeight - 200 }} />;
  }

  if (props.selectedTab == "ar_view") {
    return (
      <ArModelView
        arModel={
          props?.modelData?.modelPath?.glb
            ? props?.modelData?.modelPath?.glb
            : ""
        }
        onCancel={props.onCancel}
        onSubmit={props.onSubmit}
      />
    );
  }

  return (
    <Grid
      component="form"
      onSubmit={onSubmit}
      container
      direction="column"
      sx={styles.mainGrid}
    >
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={12} md={5.5}>
          <Grid
            sx={{
              flex: 1,
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid>
              <Grid container direction="row" item xs={12}>
                <UploadFile
                  page={"addResItemImg"}
                  uploadText={"Drag and Drop your Images Here"}
                  maxFiles={1}
                  imageLength={imageList.length}
                  imageLoading={imageLoading || btnDisabled}
                  imageList={imageList}
                  onRemoveImage={onRemoveImage}
                  uploadFile={uploadFile}
                  uploadError={uploadError}
                />
              </Grid>

              <Grid sx={{ mt: 3 }}>
                <Grid item mb={3}>
                  <Box className={`${styles1["hide-img-thumbnail"]}`}>
                    <Typography style={styles.labelText} mr={1}>
                      Menu Enabled
                    </Typography>
                    <SwitchInput
                      checked={menuEnabled}
                      onChange={handlerMenuEnabled}
                    />
                  </Box>
                </Grid>
                <Grid item mb={3}>
                  <Box className={`${styles1["hide-img-thumbnail"]}`}>
                    <Typography style={styles.labelText} mr={1}>
                      Hide Menu Thumb Nail Images
                    </Typography>
                    <SwitchInput
                      checked={hideMenuThumbNailImg}
                      onChange={handlerHideMenuThumbNailImg}
                    />
                  </Box>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      required
                      focused
                      fullWidth
                      color="gray"
                      id="title"
                      label="Menu Title"
                      value={menuTitle}
                      onChange={(e) => {
                        setMenuTitle(e.target.value);
                        setPrintDisplayMenu(e.target.value);
                      }}
                      variant="standard"
                      sx={textInputCss}
                      InputLabelProps={{
                        style: { color: THEME_COLOR3 },
                        sx: { ...styles.inputLabelText },
                      }}
                      inputProps={{
                        autoComplete: "none",
                        sx: { ...styles.inputText },
                      }}
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      required
                      focused
                      fullWidth
                      color="gray"
                      id="printDisplayMenu"
                      label="Print Display Menu"
                      value={printDisplayMenu}
                      onChange={(e) => setPrintDisplayMenu(e.target.value)}
                      variant="standard"
                      sx={textInputCss}
                      InputLabelProps={{
                        style: { color: THEME_COLOR3 },
                        sx: { ...styles.inputLabelText },
                      }}
                      inputProps={{
                        autoComplete: "none",
                        sx: { ...styles.inputText },
                      }}
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      required
                      multiline
                      focused
                      fullWidth
                      color="gray"
                      id="description"
                      rowsmin={2}
                      label="Description"
                      value={descriptionValue}
                      variant="standard"
                      sx={textInputCss}
                      InputLabelProps={{
                        style: { color: THEME_COLOR3 },
                        sx: { ...styles.inputLabelText },
                      }}
                      inputProps={{
                        autoComplete: "none",
                        sx: { ...styles.inputText },
                      }}
                      onChange={(e) =>
                        handleOnChangeValue("description", e.target.value)
                      }
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <label style={styles.labelText}>Category</label>
                  <FormControl sx={{ m: 0, width: "100%" }}>
                    <ThemeProvider theme={theme}>
                      <Select
                        required
                        variant="standard"
                        color="gray"
                        sx={{
                          fontSize: 13,
                          fontFamily: "GilroyBold",
                          ...textInputCss,
                        }}
                        value={
                          menuCategory?.[0]?._id ? menuCategory?.[0]?._id : ""
                        }
                        onChange={(event) =>
                          setMenuCategory(
                            categoryList.filter(
                              (x) => x._id == event.target.value
                            )
                          )
                        }
                      >
                        {categoryList.map((item, index) => (
                          <MenuItem
                            sx={{ fontSize: 13, fontFamily: "GilroyBold" }}
                            key={index}
                            value={item._id}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </ThemeProvider>
                  </FormControl>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <label style={styles.labelText}>Price</label>
                  <Grid container direction="row">
                    <Grid item xs={2} style={{ width: "100%" }}>
                      <FormControl
                        style={{ width: "100%" }}
                        disabled={disableCurrencyDropdown}
                      >
                        <ThemeProvider theme={theme}>
                          <Select
                            required
                            variant="standard"
                            color="gray"
                            sx={{
                              ...textInputCss,
                              fontSize: 13,
                              fontFamily: "GilroyBold",
                              "&.MuiInputBase-root": {
                                "& .Mui-disabled": {
                                  textFillColor: "#000 !important",
                                },
                                "& .MuiSvgIcon-root": {
                                  color: `${
                                    disableCurrencyDropdown
                                      ? "transparent"
                                      : "#000"
                                  }`,
                                },
                              },
                            }}
                            value={priceUnit}
                            onChange={(event) =>
                              setPriceUnit(event.target.value)
                            }
                          >
                            {symbol.map((item, index) => (
                              <MenuItem
                                sx={{ fontSize: 13, fontFamily: "GilroyBold" }}
                                key={index}
                                value={item.currency}
                              >
                                {item.symbol}
                              </MenuItem>
                            ))}
                          </Select>
                        </ThemeProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={10}>
                      <ThemeProvider theme={theme}>
                        <TextField
                          required
                          focused
                          fullWidth
                          color="gray"
                          id="price"
                          variant="standard"
                          value={price}
                          onChange={(e) =>
                            setPrice(e.target.value.replace(/[^0-9.]/g, ""))
                          }
                          sx={textInputCss}
                          InputProps={{
                            autoComplete: "none",
                            sx: { fontSize: 13, fontFamily: "GilroyBold" },
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  className="menu-price-box"
                                  style={{
                                    border: "0.5px solid " + THEME_COLOR4,
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item mb={3}>
                  <label style={styles.labelText}>Takeaway Price</label>
                  <Grid container direction="row">
                    <Grid item xs={2}>
                      <Typography
                        className={`${styles1["take-away-price"]} ${styles1["currency"]}`}
                        style={{
                          borderBottom: `1px solid ${THEME_COLOR4}`,
                        }}
                      >
                        {CURRENCY_SYMBOL?.[priceUnit]}
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <ThemeProvider theme={theme}>
                        <TextField
                          focused
                          fullWidth
                          color="gray"
                          variant="standard"
                          value={takeawayPriceValue}
                          onChange={(e) => {
                            handleOnChangeValue("takeaway", e.target.value);
                          }}
                          InputProps={{
                            autoComplete: "none",
                            className: `${styles1["take-away-price"]} ${styles1["price"]}`,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  className="menu-price-box"
                                  style={{
                                    border: `0.5px solid ${THEME_COLOR4}`,
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={{ mb: 3 }}>
                  <label style={styles.labelText}>Menu Type</label>
                  <FormControl sx={{ m: 0, width: "100%" }}>
                    <ThemeProvider theme={theme}>
                      <Select
                        required
                        variant="standard"
                        color="gray"
                        sx={{
                          fontSize: 13,
                          fontFamily: "GilroyBold",
                          ...textInputCss,
                        }}
                        value={menuTypeValue}
                        onChange={(e) =>
                          handleOnChangeValue("menuType", e.target.value)
                        }
                      >
                        {MENU_TYPE.map((item, index) => (
                          <MenuItem
                            sx={{ fontSize: 13, fontFamily: "GilroyBold" }}
                            key={index}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </ThemeProvider>
                  </FormControl>
                </Grid>

                <Grid item mb={3}>
                  <label style={styles.labelText}>Menu Item Order Type</label>
                  <FormControl sx={styles.selectBoxFormControl}>
                    <ThemeProvider theme={theme}>
                      <Select
                        required
                        variant="standard"
                        color="gray"
                        sx={{
                          ...styles.selectBox,
                          ...textInputCss,
                        }}
                        value={menuOrderType}
                        onChange={(e) =>
                          handleOnChangeValue(
                            "menuItemOrderType",
                            e.target.value
                          )
                        }
                      >
                        {OrderTypes.map((item, index) => (
                          <MenuItem
                            sx={styles.menuItem}
                            key={index}
                            value={item.value}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </ThemeProvider>
                  </FormControl>
                </Grid>

                <Grid mt={3}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      focused
                      fullWidth
                      multiline
                      rowsmin={2}
                      color="gray"
                      id="allergies"
                      label="Allergies"
                      value={allergiesValue}
                      variant="standard"
                      sx={textInputCss}
                      InputLabelProps={{
                        style: { color: THEME_COLOR3 },
                        sx: { ...styles.inputLabelText },
                      }}
                      inputProps={{
                        autoComplete: "none",
                        sx: { ...styles.inputText },
                      }}
                      onChange={(e) =>
                        handleOnChangeValue("allergies", e.target.value)
                      }
                    />
                  </ThemeProvider>
                </Grid>

                <Grid mt={3}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      focused
                      fullWidth
                      multiline
                      rowsmin={2}
                      color="gray"
                      id="calories"
                      label="Calories"
                      value={caloriesValue}
                      variant="standard"
                      sx={textInputCss}
                      InputLabelProps={{
                        style: { color: THEME_COLOR3 },
                        sx: { ...styles.inputLabelText },
                      }}
                      inputProps={{
                        autoComplete: "none",
                        sx: { ...styles.inputText },
                      }}
                      onChange={(e) =>
                        handleOnChangeValue("calories", e.target.value)
                      }
                    />
                  </ThemeProvider>
                </Grid>

                <Grid mt={3}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      focused
                      fullWidth
                      multiline
                      rowsmin={2}
                      color="gray"
                      id="nutritions"
                      label="Nutritions"
                      value={nutritionValue}
                      variant="standard"
                      sx={textInputCss}
                      InputLabelProps={{
                        style: { color: THEME_COLOR3 },
                        sx: { ...styles.inputLabelText },
                      }}
                      inputProps={{
                        autoComplete: "none",
                        sx: { ...styles.inputText },
                      }}
                      onChange={(e) =>
                        handleOnChangeValue("nutritions", e.target.value)
                      }
                    />
                  </ThemeProvider>
                </Grid>

                <Grid mb={2} mt={3}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      focused
                      fullWidth
                      multiline
                      rowsmin={2}
                      color="gray"
                      id="dishNotes"
                      label="Dish Notes"
                      value={dishNotes}
                      variant="standard"
                      sx={textInputCss}
                      InputLabelProps={{
                        style: { color: THEME_COLOR3 },
                        sx: { ...styles.inputLabelText },
                      }}
                      inputProps={{
                        autoComplete: "none",
                        sx: { ...styles.inputText },
                      }}
                      onChange={(e) => setDishNotes(e.target.value)}
                    />
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5.5}>
          <AddModifiers
            restaurantId={props.restaurantId}
            categoryList={categoryList}
            menuList={menuList}
            modifiers={modifierList}
            currency={priceUnit}
            setModifierList={setModifierDataList}
          />
          <Box mt={1}>
            <AddOptions
              options={optionList}
              setOptionList={setOptionDataList}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid mt={2} container direction="row" justifyContent="center">
        <Button
          disabled={imageLoading || btnDisabled}
          type="button"
          sx={styles.cancelBtn}
          onClick={() => props.onCancel()}
        >
          Cancel
        </Button>
        <Button
          disabled={imageLoading || btnDisabled}
          type="submit"
          sx={styles.saveBtn}
        >
          {imageLoading || btnDisabled
            ? "Please Wait"
            : props.itemData == null
            ? "Add Menu"
            : "Update Menu"}
        </Button>
      </Grid>
      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
    </Grid>
  );
};

export default AddSettingItem;

const styles = {
  mainGrid: {
    width: "100%",
    minHeight: "100%",
    py: 1,
    px: 5,
  },
  titleText: {
    fontFamily: "GilroyBold",
    fontSize: 18,
    pt: 1,
    pb: 3,
  },
  inputLabelText: {
    fontFamily: "GilroyRegular",
  },
  inputText: {
    fontSize: 13,
    fontFamily: "GilroySemiBold",
    my: 0.8,
  },
  labelText: {
    color: THEME_COLOR3,
    fontFamily: "GilroyRegular",
    fontSize: 12,
  },
  cancelBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    backgroundColor: "#FFF",
    color: "#000",
    fontFamily: "GilroyBold",
    fontSize: 14,
    borderRadius: 1,
    border: "2px solid #000000",
    width: 150,
    height: 44,
    "&:hover": {
      backgroundColor: "#FFF !important",
    },
  },
  saveBtn: {
    backgroundColor: BTN_COLOR,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    color: BTN_TEXT_COLOR,
    fontFamily: "GilroyBold",
    fontSize: 14,
    borderRadius: 1,
    width: 150,
    height: 44,
    "&:hover": {
      backgroundColor: BTN_COLOR + " !important",
    },
    ml: 2,
  },
  selectBoxFormControl: {
    width: "100%",
    m: 0,
  },
  selectBox: {
    fontSize: 13,
    fontFamily: "GilroyBold",
  },
  menuItem: {
    fontSize: 13,
    fontFamily: "GilroyBold",
  },
};
