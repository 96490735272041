import React, { useState, forwardRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import AddRestaurantInfo from "../AddRestaurantInfo";
import AddRestaurantImagesUpload from "./AddRestaurantImagesUpload";
import AddRestaurantLocation from "../AddRestaurantLocation";
import AddRestaurantOpeningHours from "../AddRestaurantOpeningHours";
import getUserLocation from "../../functions/getUserLocation";

import * as Api from "../../../src/api";

import "../../../src/assets/css/custom.css";

import {
  THEME_COLOR,
  LOADING_COLOR,
  APP_TYPE_FOODO,
} from "../../../src/constants";

const steps = ["Restaurant Info", "Images Upload", "Location", "Opening Hours"];

export const allDays = "all days";

const daysList = [
  allDays,
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const openTime = new Date(
  moment("10:00", "H:mm").format("YYYY-MM-DD HH:mm:ss")
);
const closeTime = new Date(
  moment("22:00", "H:mm").format("YYYY-MM-DD HH:mm:ss")
);

const openHour = daysList.map((day) => {
  return {
    day: day,
    active: true,
    open: openTime,
    close: closeTime,
  };
});

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme({
  palette: {
    loading_color: {
      main: LOADING_COLOR,
    },
  },
});

const AddRestaurant = (props) => {
  const [loading, setLoading] = useState(true);
  const [cardLoading, setCardLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [phCountryCode, setPhCountryCode] = useState("");
  const [desc, setDesc] = useState("");
  const [slug, setSlug] = useState("");
  const [category, setCategory] = useState("");
  const [option, setOption] = useState("dine_in");
  const [image, setImage] = useState([]);
  const [logoImage, setLogoImage] = useState("");
  const [firstLine, setFirstLine] = useState("");
  const [secondLine, setSecondLine] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const navigate = useNavigate();

  const [openingHourList, setOpeningHourList] = useState(
    props.type == "edit" ? [] : openHour
  );

  useEffect(() => {
    if (props.type === "edit") {
      let resData = props.resData;
      setName(resData.name);
      // setEmail(resData.email);
      let contactNumber = resData.mobile;
      setMobile(contactNumber);
      setPhCountryCode(resData.country);
      setDesc(resData.description);
      setSlug(resData?.slug ? resData?.slug : "");
      setCategory(resData.category);

      let options = "";
      if (resData.options?.hasDineIn && resData.options?.hasTakeAway) {
        options = "both";
      } else if (resData.options?.hasDineIn && !resData.options?.hasTakeAway) {
        options = "dine_in";
      } else if (resData.options?.hasTakeAway && !resData.options?.hasDineIn) {
        options = "take_away";
      }
      setOption(options);

      setImage(
        resData.images?.map((x) => {
          let index = x?.lastIndexOf("/") + 1;
          let filename = x?.substr(index);
          return {
            id: new Date(),
            name: filename,
            displayName: filename,
            size: 0,
            image: x,
            upload: true,
          };
        })
      );

      setFirstLine(resData.address?.firstLine);
      setSecondLine(resData.address?.secondLine);
      setPostcode(resData.address?.postCode);
      setCity(resData.address?.city);
      setCountry(resData.address?.countryCode);
      setLat(resData.address?.coords[0]);
      setLng(resData.address?.coords[1]);

      let res_logo = resData.logo;
      let index1 = res_logo?.lastIndexOf("/") + 1;
      let filename1 = res_logo?.substr(index1);
      setLogoImage({
        id: new Date(),
        name: filename1,
        displayName: filename1,
        size: 0,
        image: res_logo,
        upload: true,
      });

      const monTime = resData?.openHours?.["monday"]?.timings?.[0];

      const openingHrList = daysList.map(function (day) {
        const workHr = resData?.openHours?.[day];
        let openHrTime = openTime;
        let closeHrTime = closeTime;
        if (workHr?.isOpen || (day === allDays && monTime)) {
          const { from, to } =
            day === allDays ? monTime : workHr?.timings?.[0];
          openHrTime = new Date(
            moment(from, "H:mm").format("YYYY-MM-DD HH:mm:ss")
          );
          closeHrTime = new Date(
            moment(to, "H:mm").format("YYYY-MM-DD HH:mm:ss")
          );
        }
        return {
          day: day,
          active: workHr?.isOpen || day === allDays,
          open: openHrTime,
          close: closeHrTime,
        };
      });

      setOpeningHourList(openingHrList);
    }
  }, [props.resData]);

  useEffect(() => {
    if (typeof props?.categoryList == "undefined") {
      getRestaurantCategoryList();
    } else {
      setCategoryList(props?.categoryList);
      setLoading(false);
    }
  }, [props?.categoryList]);

  useEffect(() => {
    if (props.type === "add") {
      getCurrentLocation();
    }
  }, []);

  const getCurrentLocation = () => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
      setMsgAlert({
        open: true,
        message: "Geolocation is not supported by your browser",
        msgType: "error",
      });
    } else {
      // setIsLoading(true);
      console.log("Locating...");
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          console.log("Latitude is :", position?.coords.latitude);
          console.log("Longitude is :", position?.coords.longitude);
          let result = await getUserLocation(position?.coords);
          setPhCountryCode("GB");
          setLat(position?.coords.latitude);
          setLng(position?.coords.longitude);
          if (result.success) {
            setPostcode(result?.data?.pincode);
            setCity(result?.data?.city);
            setCountry(result?.data?.country);
            if(result?.data?.countryCode) {
              setPhCountryCode(result?.data?.countryCode);
            }
          } else {
            setMsgAlert({
              open: true,
              message: result.msg,
              msgType: "error",
            });
          }
        },
        () => {
          // setIsLoading(false);
          console.log("Unable to retrieve your location");
          setMsgAlert({
            open: true,
            message: "Unable to retrieve your location",
            msgType: "error",
          });
        }
      );
    }
  };

  const getRestaurantCategoryList = () => {
    Api.getRestaurantCategoryList("all").then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setCategoryList(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const resInfo = (data) => {
    setName(data.name);
    setEmail(data.email);
    setMobile(data.mobile);
    setPhCountryCode(data?.countryCode);
    setDesc(data.desc);
    setSlug(data?.slug);
    setCategory(data.category);
    setOption(data.option);
    setActiveTab(1);
  };

  const resImageUpload = (data, logo, type) => {
    if (type == "prev") {
      setActiveTab(0);
    } else {
      setImage(data);
      setLogoImage(logo);
      setActiveTab(2);
    }
  };

  const resLocation = (data) => {
    setFirstLine(data?.firstLine);
    setSecondLine(data?.secondLine);
    setPostcode(data?.postCode);
    setCity(data?.city);
    setCountry(data.country);
    setLat(data.lat);
    setLng(data.lng);
    setActiveTab(3);
  };

  const resOpeningHours = (data) => {
    setOpeningHourList(data);

    let imageData = [];
    let imageUrlList = [];
    image.forEach((item, index) => {
      imageData.push(item.name);
      imageUrlList.push(item.image);
    });

    let openHourData = [];
    data.forEach((item, index) => {
      if (item.active) {
        openHourData[item.day] = {
          isOpen: true,
          timings: [
            {
              from: moment(item.open).format("H:mm"),
              to: moment(item.close).format("H:mm"),
            },
          ],
        };
      } else {
        openHourData[item.day] = {
          isOpen: false,
          timings: [],
        };
      }
    });

    let data1 = {
      name: name,
      contactNumber: mobile,
      country: phCountryCode,
      _idCategory: category,
      description: desc,
      slug: slug ? slug : null,
      options: {
        hasDineIn: option == "dine_in" || option == "both",
        hasTakeAway: option == "take_away" || option == "both",
      },
      address: {
        firstLine: firstLine,
        secondLine: secondLine,
        city: city,
        countryCode: country,
        postCode: postcode,
        coords: [lat, lng],
      },
      openHours: {
        sunday: openHourData["sunday"],
        monday: openHourData["monday"],
        tuesday: openHourData["tuesday"],
        wednesday: openHourData["wednesday"],
        thursday: openHourData["thursday"],
        friday: openHourData["friday"],
        saturday: openHourData["saturday"],
      },
      images: imageData,
      logo: logoImage.name,
    };
    if (props.type === "add") {
      data1 = {
        ...data1,
        email: email,
        appType: APP_TYPE_FOODO,
      };
    }

    let datas = JSON.stringify(data1);

    setCardLoading(true);
    if (props.type === "edit") {
      Api.updateRestaurant(datas, props.restaurantId).then((response) => {
        if (response.success) {
          setMsgAlert({
            open: true,
            message: response.msg,
            msgType: "success",
          });
          let res_data = {
            name: data1.name,
            email: data1.email,
            address: data1.address,
            contactNumber: data1.contactNumber,
            country: data1.country,
            description: data1.description,
            slug: data1.slug ? data1.slug : "",
            _idCategory: data1._idCategory,
            openHours: data1.openHours,
            options: data1.options,
            images: imageUrlList,
            logo: logoImage.image,
          };

          props.onSubmit(res_data);
        } else {
          setCardLoading(false);
          setMsgAlert({
            open: true,
            message: response.msg,
            msgType: "error",
          });
        }
      });
    } else {
      Api.addRestaurant(datas).then((response) => {
        if (response.success) {
          setMsgAlert({
            open: true,
            message: response.msg,
            msgType: "success",
          });
          navigate("/restaurants");
        } else {
          setCardLoading(false);
          setMsgAlert({
            open: true,
            message: response.msg,
            msgType: "error",
          });
        }
      });
    }
  };

  return (
    <>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: window.innerHeight - 150 }}
        >
          <ThemeProvider theme={theme}>
            <CircularProgress color="loading_color" />
          </ThemeProvider>
        </Grid>
      ) : (
        <Grid>
          {props.type == "add" ? (
            <Typography variant={"h6"} sx={styles.restaurantInfo}>
              Restaurant Info
            </Typography>
          ) : null}
          <Grid container alignItems="center" justifyContent="center" mt={3}>
            {props.type == "edit" ? (
              <Grid item xs={12} px={7}>
                <Typography variant={"h6"} sx={styles.restaurantInfo}>
                  Edit Info
                </Typography>
              </Grid>
            ) : null}
            <Card elevation={0} className="add-card">
              <Box
                sx={{ width: "80%" }}
                mt={props.type == "edit" ? 1 : 5}
                mb={5}
              >
                <Stepper activeStep={activeTab} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label} sx={styles.mainStep}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              {activeTab == 0 ? (
                <Grid
                  container
                  direction="column"
                  style={{ width: props.type == "edit" ? "90%" : "75%" }}
                  mt={4}
                >
                  <AddRestaurantInfo
                    type={props.type}
                    name={name}
                    email={email}
                    desc={desc}
                    mobile={mobile}
                    countryCode={phCountryCode}
                    slug={slug}
                    category={category}
                    option={option}
                    categoryList={categoryList}
                    resInfo={resInfo}
                  />
                </Grid>
              ) : activeTab == 1 ? (
                <Grid
                  container
                  direction="column"
                  style={{ width: props.type == "edit" ? "90%" : "75%" }}
                  mt={4}
                >
                  <AddRestaurantImagesUpload
                    image={image}
                    logoImage={logoImage}
                    resImageUpload={resImageUpload}
                  />
                </Grid>
              ) : activeTab == 2 ? (
                <Grid
                  container
                  direction="column"
                  style={{ width: props.type == "edit" ? "90%" : "75%" }}
                  mt={4}
                >
                  <AddRestaurantLocation
                    firstLine={firstLine}
                    secondLine={secondLine}
                    postcode={postcode}
                    city={city}
                    country={country}
                    lat={lat}
                    lng={lng}
                    resLocation={resLocation}
                    previousBtnClick={() => setActiveTab(1)}
                  />
                </Grid>
              ) : activeTab == 3 ? (
                cardLoading ? (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: window.innerHeight - 150 }}
                  >
                    <ThemeProvider theme={theme}>
                      <CircularProgress color="loading_color" />
                    </ThemeProvider>
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="column"
                    style={{ width: props.type == "edit" ? "90%" : "75%" }}
                  >
                    <AddRestaurantOpeningHours
                      openingHourList={openingHourList}
                      resOpeningHours={resOpeningHours}
                      previousBtnClick={() => setActiveTab(2)}
                    />
                  </Grid>
                )
              ) : null}
            </Card>
          </Grid>

          <Snackbar
            open={msgAlert.open}
            autoHideDuration={6000}
            onClose={onCloseAlertMsg}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={onCloseAlertMsg}
              severity={msgAlert.msgType}
              sx={{ width: "100%" }}
            >
              {msgAlert.message}
            </Alert>
          </Snackbar>
        </Grid>
      )}
    </>
  );
};

export default AddRestaurant;

const styles = {
  restaurantInfo: {
    fontFamily: "GilroyBold",
  },
  mainStep: {
    "& .MuiStepLabel-label": {
      fontFamily: "GilroySemiBold",
    },
    "& .MuiStepLabel-root .Mui-active": {
      color: THEME_COLOR, // circle color (ACTIVE)
      fontFamily: "GilroyBold",
    },
    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
      color: "#000", // Just text label (ACTIVE)
      fontFamily: "GilroyBold",
    },
    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
      fill: "#000", // circle's number (ACTIVE)
      fontFamily: "GilroyBold",
    },
    "& .MuiStepLabel-root .Mui-completed": {
      color: THEME_COLOR, // circle color (COMPLETED)
      fontFamily: "GilroyBold",
    },
    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
      color: "#000", // Just text label (COMPLETED)
      fontFamily: "GilroySemiBold",
    },
    "& .MuiStepLabel-root .Mui-completed .MuiStepIcon-text": {
      fill: "#000", // circle's number (COMPLETED)
      fontFamily: "GilroyBold",
    },
  },
  btnText: {
    backgroundColor: THEME_COLOR,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontFamily: "GilroyBold",
    fontSize: 13,
    borderRadius: 1,
    width: 100,
    height: 35,
  },
};
